import React from "react";
import { Col, Container, Row, } from "react-bootstrap";
import "./Footer.scss";
import { useLanguage } from "hooks";
import { BiLogoLinkedin, BiLogoFacebook } from 'react-icons/bi';
import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  const { translation } = useLanguage();

  const navLinks = [
    {
      title: translation.home,
      link: "#home",
    },
    {
      title: translation.about,
      link: "#about",
    },
    {
      title: translation.features,
      link: "#features",
    },
    {
      title: translation.plans,
      link: "#plans",
    },
  ];


  return (
    <div id="Footer">
      <Container>
        <div className="footer-details">
          <Row className="align-items-center gap-5 gap-sm-0 py-5 py-md-0">
            <Col xs={12} sm={8} md={8} lg={9} xl={9}>
              <img
                src="/Images/logo.svg"
                alt="logo"
                height="100%"
                className="img"
              />
            </Col>
            <Col xs={12} sm={4} md={4} lg={3} xl={3}>
              <div>
                <Row>
                  {navLinks.map((item, idx) => (
                    <Col xs={6} key={idx}>
                      <div>
                        <a href={item.link} className="fs-6 text-white hover">{item.title}</a>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="mt-4">
                <Row className="gy-3">
                  <Col xs={4} md={2}>
                    <a href="https://www.linkedin.com/" target="blank"><BiLogoLinkedin size={30} className="text-star" /></a>
                  </Col>
                  <Col xs={4} md={2}>
                    <a href="https://twitter.com/" target="blank"><FaXTwitter size={30} className="text-star" /></a>
                  </Col>
                  <Col xs={4} md={2}>
                    <a href="https://www.instagram.com/" target="blank"><AiOutlineInstagram size={30} className="text-star" /></a>
                  </Col>
                  <Col xs={4} md={2}>
                    <a href="https://www.facebook.com/" target="blank"><BiLogoFacebook size={30} className="text-star" /></a>
                  </Col>
                  <Col xs={4} md={2}>
                    <a href="https://www.youtube.com/" target="blank"><AiFillYoutube size={30} className="text-star" /></a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
