import React from "react";
import { AnimationFade, CustomCard, Divider, Layout } from "components";
import { Container, Row, Col, } from "react-bootstrap";
import { useLanguage } from "hooks";
import "./Home.scss";
import 'react-loading-skeleton/dist/skeleton.css'


function Home() {
  const { translation } = useLanguage();
  // const [showSkeleton, setShowSkeleton] = useState(true);

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     setShowSkeleton(false);
  //   }, 7000);

  //   return () => {
  //     clearTimeout(delay);
  //   };
  // }, []);


  const category = [
    {
      title: translation.registrations,
    },
    {
      title: translation.registrationPortal,
    },
    {
      title: translation.teachersAndMarks,
    },
    {
      title: translation.financeManagement,
    },
  ]

  const category1 = [
    {
      title: translation.registrations,
    },
    {
      title: translation.registrationPortal,
    },
    {
      title: translation.teachersAndMarks,
    },
    {
      title: translation.financeManagement,
    },
    {
      title: translation.busesAndTransportations,
    },
    {
      title: translation.studentsHealthCare,
    },
    {
      title: translation.libraryManagement,
    },
  ]

  const category2 = [
    {
      title: translation.registrations,
    },
    {
      title: translation.registrationPortal,
    },
    {
      title: translation.teachersAndMarks,
    },
    {
      title: translation.financeManagement,
    },
    {
      title: translation.busesAndTransportations,
    },
    {
      title: translation.studentsHealthCare,
    },
    {
      title: translation.libraryManagement,
    },
    {
      title: translation.studentsRegistrationsPortalSite,
    },
    {
      title: translation.parentsAndStudentPortalSite,
    },
  ]

  const category3 = [
    {
      title: translation.registrations,
    },
    {
      title: translation.registrationPortal,
    },
    {
      title: translation.teachersAndMarks,
    },
    {
      title: translation.financeManagement,
    },
    {
      title: translation.busesAndTransportations,
    },
    {
      title: translation.studentsHealthCare,
    },
    {
      title: translation.libraryManagement,
    },
    {
      title: translation.studentsRegistrationsPortalSite,
    },
    {
      title: translation.parentsAndStudentPortalSite,
    },
    {
      title: translation.smartCafeteria,
    },
    {
      title: translation.exam,
    },
  ]

  return (
    <Layout>
      <div id="home-page" className="pt-5 position-relative">
        <Container>
          <div id="home" className="position-relative" dir="ltr">
            <Row className="align-items-center gap-5 gap-md-0">
              <Col xs={12} md={5} lg={5} xl={6}>
                <div>
                  <div className="left-circle">
                    <div className="left-circle-div">
                      <div className="left-circle-details" />
                    </div>
                  </div>
                  <AnimationFade>
                    <h1 className="text-center">
                      <span>{translation.makeYourSchool}</span><br /><span>{translation.moreDisciplinedAndSafe}</span>
                    </h1>
                  </AnimationFade>
                </div>
              </Col>
              <Col xs={12} md={7} lg={7} xl={6}>
                <div className="text-center text-lg-end position-relative">
                  <AnimationFade>
                    <img
                      src="/Images/rightImg2-min.png"
                      alt="rightImg"
                      width="100%"
                      height="100%"
                      loading="lazy"
                    />
                    <div className="warrap-top">
                      <div className="warrap-details-top">
                        <div className="warrap-div-top">
                          <img
                            src="/Images/blueCircle.png"
                            alt="blueCircle"
                            className="twist-animation-top"
                          />
                        </div>
                      </div>
                    </div>
                  </AnimationFade>
                  <div className="right-circle">
                    <div className="right-circle-div">
                      <div className="right-circle-details" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div id="about" className="mt-5 position-relative" dir="ltr">
            <Row className="align-items-center">
              <Col xs={12} md={7} lg={6}>
                <div className="left-section text-center text-lg-end position-relative">
                  {/* <div className="left-about-circle">
                    <div className="left-about-circle-div">
                      <div className="left-about-circle-details" />
                    </div>
                  </div> */}
                  <AnimationFade>
                    <div className="warrap">
                      <div className="warrap-details">
                        <div className="warrap-div">
                          <img
                            src="/Images/redCircle.png"
                            alt="redCircle"
                            className="twist-animation"
                          />
                        </div>
                      </div>
                    </div>
                    <img
                      src="/Images/secondImg3.png"
                      alt="leftImg"
                      width="100%"
                      height="auto"
                      loading="lazy"
                    />
                  </AnimationFade>
                </div>
              </Col>
              <Col xs={12} md={5} lg={6}>
                <AnimationFade>
                  <div>
                    <h3 className="about-title">
                      {translation.about}
                    </h3>
                    <h6 className="about-description mt-2">
                      {translation.aboutParg}
                    </h6>
                  </div>
                </AnimationFade>
                <div className="right-about-circle">
                  <div className="right-about-circle-div">
                    <div className="right-about-circle-details" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div id="features" className="features">
            <div className="features-right-img" />
            <Row className="align-items-center">
              <Col xs={12} >
                <AnimationFade>
                  <div className="text-center">
                    <h2 className="title-under-about">{translation.bodyTitle}</h2>
                    <h1 className="subtitle-under-about">{translation.bodySubTitle}</h1>
                    <Divider />
                  </div>
                </AnimationFade>
              </Col>
            </Row>
          </div>

          <div className="under-features text-center">
            <Row className="justify-content-center">
              <Col xs={12}>
                <div className="text-center">
                  <AnimationFade>
                    <img
                      src="/Images/school.png"
                      alt="school"
                      className="school"
                      loading="lazy"
                    />
                  </AnimationFade>
                </div>
              </Col>
              <Col xs={12}>
                <Row className="align-items-center" dir="ltr">
                  <Col xs={12} md={6} xl={4}>
                    <AnimationFade>
                      <div className="first-row-first-img">
                        <h5 className="text1">
                          {translation.cateringSystem}
                        </h5>
                        <img
                          src="/Images/Cafeteria-min.gif"
                          alt="Cafeteria"
                          loading="lazy"
                        />
                      </div>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <AnimationFade>
                      <div className="first-row-second-img">
                        <h5 className="text2">
                          {translation.eGates}
                        </h5>
                        <img
                          src="/Images/wifi_2-min.gif"
                          alt="wifi_2"
                          className="wifi"
                          loading="lazy"
                        />
                        <img
                          src="/Images/RFID.svg"
                          alt="RFID"
                          className="second_img"
                          loading="lazy"
                        />
                        <img
                          src="/Images/wifi_2-min.gif"
                          alt="wifi_2"
                          className="wifi2"
                          loading="lazy"
                        />
                        <h5 className="text3">
                          {translation.studentsTimeAttendance}
                        </h5>
                      </div>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={12} xl={4}>
                    <AnimationFade>
                      <div className="first-row-third-img">
                        <h5 className="text4">
                          {translation.studentsCall}
                        </h5>
                        <img
                          src="/Images/wifi-min.gif"
                          alt="icons-09"
                          className="wifi-car"
                          loading="lazy"
                        />
                        <img
                          src="/Images/car-min.gif"
                          alt="car"
                          className="img1"
                          loading="lazy"
                        />
                        <img
                          src="/Images/SchoolBus-min.gif"
                          alt="School Bus"
                          className="img2"
                          loading="lazy"
                        />
                        <h5 className="text5">
                          {translation.toursTracking}
                        </h5>
                      </div>
                    </AnimationFade>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} xl={10}>
                <AnimationFade>
                  <div className="second-row">
                    <h5 className="text6">
                      {translation.teachersManagement}
                    </h5>
                    <h5 className="text7">
                      {translation.parentsManagement}
                    </h5>
                    <h5 className="text8">
                      {translation.studentsManagement}
                    </h5>
                    <img
                      src="/Images/family.svg"
                      alt="Managment part"
                      className="family-img"
                      loading="lazy"
                    />
                    <img
                      src="/Images/Managmentpart-min.gif"
                      alt="Managment part"
                      className="part-img"
                      loading="lazy"
                    />
                  </div>
                </AnimationFade>
              </Col>
              <Col xs={12} xl={9} className="mt-5 mt-lg-2 mt-xl-5">
                <div className="third-row">
                  <Row className="align-items-end gap-4 gap-md-0">
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text9">
                            {translation.registration}
                          </h5>
                          <img
                            src="/Images/1-min.png"
                            alt="Managment part"
                            className="row4-image1"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text10">
                            {translation.activitiesEvents}
                          </h5>
                          <img
                            src="/Images/2-min.png"
                            alt="Managment part"
                            className="row4-image2"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text12">
                            {translation.uniformBooksSales}
                          </h5>
                          <img
                            src="/Images/4-min.svg"
                            alt="Managment part"
                            className="row4-image3"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3}>
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text11">
                            {translation.financialManagement}
                          </h5>
                          <img
                            src="/Images/4-min.gif"
                            alt="Managment part"
                            className="row4-image4"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} xl={9}>
                <div className="four-row mt-5">
                  <Row className="justify-content-center align-items-end">
                    <Col xs={12} md={6} lg={3} xl={2} >
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text13">
                            {translation.liveClasses}
                          </h5>
                          <img
                            src="/Images/5.png"
                            alt="Managment part"
                            className=""
                            width="100%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={2} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text14">
                            {translation.eLearning}
                          </h5>
                          <img
                            src="/Images/6.gif"
                            alt="Managment part"
                            className=""
                            width="100%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-5 mt-lg-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text15">
                            {translation.libraries}
                          </h5>
                          <img
                            src="/Images/7-min.svg"
                            alt="Managment part"
                            className="row5-image3"
                            width="100%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={2} className="mt-5 mt-md-5 mt-lg-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text16">
                            {translation.examsGrades}
                          </h5>
                          <img
                            src="/Images/8.gif"
                            alt="Managment part"
                            className="row5-image4"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={2} className="mt-5 mt-md-5 mt-xl-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text17">
                            {translation.healthcareSystem}
                          </h5>
                          <img
                            src="/Images/16New.png"
                            alt="9.png"
                            className="row5-image5"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={10}>
                <div className="five-row mt-5">
                  <Row className="justify-content-center align-items-center">
                    <Col xs={12} md={6} lg={3} xl={3}>
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text18">
                            {translation.erpSystem}
                          </h5>
                          <img
                            src="/Images/10-min.gif"
                            alt="10.gif"
                            className=""
                            width="80%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3}>
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text19">
                            {translation.cloud}
                          </h5>
                          <img
                            src="/Images/11-min.gif"
                            alt="11.gif"
                            className=""
                            width="80%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={10}>
                <div className="five-row mt-5">
                  <Row className="justify-content-center align-items-center">
                    <Col xs={12} md={6} xl={4}>
                      <AnimationFade>
                        <img
                          src="/Images/12.svg"
                          alt="10.gif"
                          className=""
                          width="100%"
                          height="auto"
                          loading="lazy"
                        />
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <div className="plans-warrap">
          <div id="plans" className="div-lines">
            <div className="lines text-center">
              <h1>{translation.plans}</h1>
            </div>
          </div>
          <div className="position-relative">
            <div className="left-plans-circle">
              <div className="left-plans-circle-div">
                <div className="left-plans-circle-details" />
              </div>
            </div>
            <div className="plans-section pt-5">
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={4} xl={3}>
                    <div className="card1">
                      <CustomCard category={category} color="text-star" bg="star" />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={3}>
                    <div className="card2">
                      <CustomCard category={category1} color="text-star1" bg="star1" />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={3}>
                    <div className="card3">
                      <CustomCard category={category2} color="text-danger1" bg="danger1" />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4} xl={3}>
                    <div className="card4">
                      <CustomCard category={category3} color="text-secondary2" bg="secondary2" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="right-plans-circle">
              <div className="right-plans-circle-div">
                <div className="right-plans-circle-details" />
              </div>
            </div>
            <div className="plans-details" />
            <div className="left-plans-small-circle">
              <div className="left-plans-small-circle-div">
                <div className="left-plans-small-circle-details" />
              </div>
            </div>
          </div>

          <div className="last-section" />
        </div>

      </div>
    </Layout>
  );
}

export default Home;
