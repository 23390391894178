import React from "react";
import { Button, Card, } from "react-bootstrap";
import "./CustomCard.scss";
import { AiFillCheckCircle } from "react-icons/ai";
import { useLanguage } from "hooks";

function CustomCard({ bg, category, color }) {
  const { translation } = useLanguage();

  return (
    <div className="custom-card">
      <Card className="custom-card-style rounded-4">
        <div className="text-center mt-5" >
          <div className={`custom-card-details bg-${bg} rounded-circle`}>
            <div className="text-start">
              <h4 className="title">{translation.standar}</h4>
              <div>
                <h3 className="price">85$</h3>
                <p className="unber-price">{translation.perStudent}</p>
              </div>
            </div>
          </div>
        </div>
        <Card.Body className="custom-card-body">
          {category.map((item, idx) => (
            <div key={idx} className="d-flex align-items-baseline gap-2 ">
              <AiFillCheckCircle className={color} />
              <Card.Text>
                {item.title}
              </Card.Text>
            </div>
          ))}
        </Card.Body>
        <div>
          <Button
            className="custom-card-button rounded-5"
          >
            <span>
              {translation.buyNow}
            </span>
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default CustomCard;
